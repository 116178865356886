/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useNavigate } from "react-router-dom";
import './setting.css'
import logo from '../../../assets/images/profile.png'
import NavBar from "../../../components/navBar";
import { useCurrentUser } from "../../../firebase/userContext";

const Setting = () => {
    const navigate = useNavigate();
    const { deleteAccount, handleLogout } = useCurrentUser();

    const handleDeleteAccount = async () => {
        const success = await deleteAccount();
        if (success) {
            navigate('/');
        } else {
            console.error('Failed to delete account')
        }
    }

    const logoutAndRedirect = async () => {
        await handleLogout(); // Log out the user
        navigate('/register'); // Redirect to home or login page
    };

    return (
        <div className="layout">
            <NavBar />
            <div className="setting-container">
            <img src={logo} className="setting-logo" />
                <p className="setting-text" >setting</p>
                <p className="settings-option" id="about" onClick={() => navigate('/about')}>about</p>
                <p className="settings-option" id="privacy">privacy</p>
                <p className="settings-option" id="terms">terms</p>
                <p className="settings-option" id="logout" onClick={logoutAndRedirect}>log out</p>
                <p className="settings-option" id="delete-account" onClick={handleDeleteAccount}>delete account</p>
            </div>
        </div>
    )
}

export default Setting;