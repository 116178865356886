/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import settingIcon from '../../assets/icons/settingIcon.png'
import messageIcon from '../../assets/icons/messageIcon.png'
import uploadIcon from '../../assets/icons/uploadIcon.png'
import './profile.css'
import NavBar from "../../components/navBar";
import { useCurrentUser } from "../../firebase/userContext";
import { db } from "../../firebase/config";
import { doc, getDoc } from "firebase/firestore";
import Gallery from "./gallery/gallery";

const Profile = () => {
    const navigate = useNavigate();
    const { currentUser } = useCurrentUser();
    const [profileData, setProfileData] = useState(null);
    const [showEmail, setShowEmail] = useState(false);

    useEffect(() => {
        const fetchProfileData = async () => {
            if (currentUser) {
                const userRef = doc(db, 'users', currentUser.uid);
                const docSnap = await getDoc(userRef);

                if (docSnap.exists()) {
                    setProfileData(docSnap.data());
                } else {
                    console.log("No profile data found")
                }
            }
        }
        fetchProfileData();
    }, [currentUser]
)

    const toggleEmail = () => {
        setShowEmail(prev => !prev)
    }

    return (
        <div className="layout">
            <NavBar />
            <Gallery />
            <img src={settingIcon} onClick={() => navigate('/setting')} className="settingicon-profile" />
            <img src={messageIcon} className="messageicon-profile" onClick={toggleEmail} />
            <img src={uploadIcon} onClick={() => navigate(`/upload/${currentUser.username}`)} className="uploadicon-profile" />
           
            <h1 className="profile-name">{profileData?.name} </h1>
            <div className="profilephoto">
               {profileData?.profilePhotoUrl ? (
                <img src={profileData.profilePhotoUrl} className="profilephoto-display" />
               ) : (
                <p>profile photo</p>
               )}
            </div>

            <h1 className="profile-category"> {profileData?.category} </h1>
            <div className="profile-bio">
                <p>{profileData?.bio} </p>
            </div>
            <button onClick={() => navigate(`/edit-profile/${currentUser.username}`)} className="editprofile-button">edit profile</button>
            {showEmail && (
                <div className="email-popup">
                    <p> {profileData?.workEmail ? profileData.workEmail : "no email"} </p>
                    <button className="email-button" onClick={toggleEmail}>X</button>
                    </div>
            )}
        </div>
    )
}

export default Profile;