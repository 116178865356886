/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import './homeModal.css'
import { useNavigate } from "react-router-dom";
import LikeButton from "../../components/likeButton";




const HomeModal = ({ photo, onClose }) => {
    const navigate = useNavigate();
   
 

     if (!photo) return null;
     const formattedDate = new Date(photo.time).toLocaleDateString();

     const handleUsernameClick = () => {
        navigate(`/creator/${photo.username}`)
     }

     
    
    


    return (
        <div className="modal-overlay" onClick={onClose} >
            <div className="homemodal-content" onClick={(e) => e.stopPropagation()}>
               <img src={photo.photoURL} className="hm-photo" />
               <h1 className="hm-name">{photo.name} </h1>
               <p className="hm-username" onClick={handleUsernameClick}>@{photo.username}</p>
               <p className="hm-description">{photo.description} </p>
               <p className="hm-time">{formattedDate} </p>
               <button className="modal-close" onClick={onClose}>X</button>
               <h1 className="share">share</h1>
               <LikeButton className="home-like" photoId={photo.id} ownerId={photo.ownerId} />
               
               
            </div>
        </div>
    )
}

export default HomeModal;