import React, { useState } from "react";
import { useNavigate } from "react-router-dom";


const Password = () => {
    const navigate = useNavigate();
    const passwordEnter = 'theprofile';
    const [inputPassword, setInputPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handlePassword = () => {
      if (inputPassword === passwordEnter) {
        navigate('/register')
      } else {
        setErrorMessage('Wrong password')
      }
    }

    return (
        <div className="layout">
            <input
            placeholder="password" 
            value={inputPassword}
            onChange={(e) => setInputPassword(e.target.value)}
            type="password"
            />
             <br />
            <button onClick={handlePassword}>enter</button>
            {errorMessage && <p className="error-message"> {errorMessage} </p>}
        </div>
    )
}

export default Password;