/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import './galleryModal.css'

const GalleryModal = ({ photo, onClose, deletePhoto }) => {
    const handleDelete = () => {
        deletePhoto(photo.id); // Call deletePhoto with the document ID
        onClose(); // Close the modal after deletion
    };

    return (
        <div className="modal-overlay" onClick={onClose}>
           <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={photo.photoURL} className="modal-photo" /> 
            <p className="modal-description"> {photo.description} </p>
            <p className="modal-time">{new Date(photo.time).toLocaleDateString()} </p>
            <button className="modal-close" onClick={onClose}>X</button>
            <button className="delete-post" onClick={handleDelete}>delete</button>
            <h1 className="share">share</h1>
           </div>
        </div>
    )
}

export default GalleryModal;