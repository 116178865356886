/* eslint-disable jsx-a11y/alt-text */
import React, { useState , useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { useCurrentUser } from "../../firebase/userContext";
import './register.css'
import logo from '../../assets/images/profile.png'

const Register = () => {
    const [isRegister, setIsRegister] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [termsChecked, setTermsChecked] = useState(false);
    const [forgotEmail, setForgotEmail] = useState('')
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
    const [resetMessage, setResetMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { currentUser, registerUser, loginUser, resetPassword,  error } = useCurrentUser();
    
    const navigate = useNavigate();

    const toggleForm = (e) => {
        setIsRegister(!isRegister)
        setErrorMessage('')
        setEmail('')
        setPassword('')
        setResetMessage('')
        e.preventDefault();
    }

    const handleRegister = async () => {
      const success = await registerUser(email, password, termsChecked);
        if (success) {
            navigate('/username')
        } else {
            setErrorMessage('Registration failed')
        }
    }

    const handleLogin = async () => {
        const user = await loginUser(email, password);
        if (user && user.username) {
            console.log('Login successful');
            navigate(`/profile/${user.username}`);
        } else {
          setErrorMessage('Please check email/password')
        }
    };
    
    
    
    useEffect(() => {
        if (currentUser && currentUser.username) {
            console.log('currentUser updated:', currentUser);
        }
    }, [currentUser]); // Just for logging or other purposes if needed
    

 

    const handleResetPassword = async () => {
        if (!forgotEmail) {
            setErrorMessage("Please enter an email.");
            return;
        }
    
        // Call resetPassword which checks the user authentication
        const response = await resetPassword(forgotEmail);
    
        if (response) { // If the response is successful
            setResetMessage('Password reset link has been sent to your email!');
            setForgotEmail('');
            setShowForgotPasswordModal(false); // Close the modal
            setErrorMessage(''); // Clear any previous error message
        } else { // If there was an error
            setErrorMessage("Could not send reset email."); // Show the error
            setResetMessage(''); // Clear reset message
        }
    };
    
    


    return (
        <div className="layout">
          
           <div className="register-container">
           {isRegister ? (
            <>
             <img src={logo} className="logo-register" />
            
            <p className="create-account">create an account</p>
            <input
            placeholder="email" 
            type="email"
            className="register-email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            />
             <input
            placeholder="password"
            type="password" 
            className="register-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            />
            
            
            <div className="terms-container">

            <input
            type="checkbox"
            className="checkbox" 
            value={termsChecked}
            onChange={(e) => setTermsChecked(e.target.checked)}
            />

            <p className="terms" onClick={() => navigate('/about')}> terms and condition  </p>
            
    
            </div>

            {error && <p className="error-message"> {error} </p>}
            
            <button className="register-button" onClick={handleRegister}>register</button>
            <button className="login-link" onClick={toggleForm}>login</button>
            {/* <button className="google-button" onClick={handleGoogleSignIn}>google</button> */}
            
            </>
           ) : (
            <>
             <img src={logo} className="logo-register" />
               <p className="create-account">log in to your account</p>
               <input
               placeholder="email"
               type="email" 
               className="register-email"
               value={email}
               onChange={(e) => setEmail(e.target.value)}
               />

               <input
               placeholder="password"
               type="password" 
               className="register-password"
               value={password}
               onChange={(e) => setPassword(e.target.value)}
               />
                {errorMessage && <p className="error-message">{errorMessage}</p>} 
               <p className="forgot-password" onClick={() => setShowForgotPasswordModal(true)}>forgot password</p>
               <button className="register-button" onClick={handleLogin}>login</button>
               <button className="login-link" onClick={toggleForm}>register</button>
               {/* <button className="google-button" onClick={handleGoogleLogIn}>google</button> */}
            </>
           )}
           </div>
           <p onClick={() => navigate('/privacy')} className="privacy">privacy</p>
           
           {showForgotPasswordModal && (
               <div className="modal-overlay">
                    <div className="forgot-modal">
                        <p className="fp">forgot password</p>
                        <input
                        type="email"
                        className="otp-email"
                        placeholder="enter-email" 
                        value={forgotEmail}
                        onChange={(e) => setForgotEmail(e.target.value)}
                        />
                       
                      
                        <button className="submit-otp" onClick={handleResetPassword}>submit</button>
                        <button className="close-modal-otp" onClick={() => {setShowForgotPasswordModal(false); setForgotEmail(''); setErrorMessage('')}}>X</button>
                        {errorMessage && (
                         <p className="em-fp"> {errorMessage} </p>
                        )}
                    </div>
                </div>
            )}
            {resetMessage && <p className="reset-message"> {resetMessage} </p>}

        </div>
    )
}

export default Register;