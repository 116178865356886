/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import NavBar from "../../../components/navBar";
import settingIcon from "../../../assets/icons/settingIcon.png";
import logo from "../../../assets/images/profile.png";
import { useNavigate } from "react-router-dom";
import { useCurrentUser } from "../../../firebase/userContext";
import "./notification.css";

const Notification = () => {
  const navigate = useNavigate();
  const { currentUser, fetchFollowers, fetchFollowing, fetchLikes } = useCurrentUser();
  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [likes, setLikes] = useState([]);
  const [activeTab, setActiveTab] = useState("all"); // Default to "all" tab

  // Fetch followers, following, and likes
  useEffect(() => {
    const loadFollowersFollowingAndLikes = async () => {
      if (currentUser) {
        const followersList = await fetchFollowers(currentUser.uid);
        setFollowers(followersList);

        const followingList = await fetchFollowing(currentUser.uid);
        setFollowing(followingList);

        const likesList = await fetchLikes(currentUser.uid);
        setLikes(likesList);
      }
    };
    loadFollowersFollowingAndLikes();
  }, [currentUser, fetchFollowers, fetchFollowing, fetchLikes]);

  const handleProfileNavigation = (username) => {
    navigate(`/creator/${username}`);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab); // Change the active tab to "followers", "following", or "all"
  };

  return (
    <div className="layout">
      <NavBar />
      <img src={logo} className="logo" />
      <img
        src={settingIcon}
        className="settingicon-profile"
        onClick={() => navigate("/setting")}
      />

      <div className="notification-container">
        <div className="not-header">
          <p className="not">notifications</p>
          <button
            className={`not-followers ${activeTab === "followers" || activeTab === "all" ? "active" : ""}`}
            onClick={() => handleTabChange("followers")}
          >
            followers
          </button>
          <button
            className={`not-following ${activeTab === "following" || activeTab === "all" ? "active" : ""}`}
            onClick={() => handleTabChange("following")}
          >
            following
          </button>
          {/* <button
            className={`not-all ${activeTab === "all" ? "active" : ""}`}
            onClick={() => handleTabChange("all")}
          >
            all
          </button> */}
        </div>

        <div className="not-background">
          <div className="notification">
            {/* Display followers, following, and likes based on the active tab */}
            {activeTab === "all" && followers.length > 0 && (
              <div>
                {followers.map((follower, index) => (
                  <p key={index} onClick={() => handleProfileNavigation(follower.username)}>
                    @{follower.username} has started following you.
                  </p>
                ))}
              </div>
            )}

            {activeTab === "all" && following.length > 0 && (
              <div>
                {following.map((followedUser, index) => (
                  <p key={index} onClick={() => handleProfileNavigation(followedUser.username)}>
                    @{followedUser.username} you are following.
                  </p>
                ))}
              </div>
            )}

            {activeTab === "all" && likes.length > 0 && (
              <div>
                {likes.map((like, index) => (
                  <p key={index} onClick={() => handleProfileNavigation(like.username)}>
                    @{like.username} liked your photo.
                  </p>
                ))}
              </div>
            )}

            {/* Display followers */}
            {activeTab === "followers" && followers.length > 0 && (
              <div>
                {followers.map((follower, index) => (
                  <p key={index} onClick={() => handleProfileNavigation(follower.username)}>
                    @{follower.username} 
                  </p>
                ))}
              </div>
            )}

            {/* Display following */}
            {activeTab === "following" && following.length > 0 && (
              <div>
                {following.map((followedUser, index) => (
                  <p key={index} onClick={() => handleProfileNavigation(followedUser.username)}>
                    @{followedUser.username} 
                  </p>
                ))}
              </div>
            )}

            {/* If no new notifications */}
            {followers.length === 0 && following.length === 0 && likes.length === 0 && (
              <p>No new notifications</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
