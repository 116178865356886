import React, { useState, useEffect } from "react";

const SelectCategory = ({ onChange, className, value }) => {
    const [selectedCategory, setSelectedCategory] = useState(value || '');

    useEffect(() => {
      setSelectedCategory(value);
    }, [value])

    const handleChange = (event) => {
      const category = event.target.value;
      setSelectedCategory(category);
      if (onChange) {
        onChange(category);
      }
    }

    return (
        <select value={selectedCategory} onChange={handleChange} className={className} >
          <option value=""> Select a category</option>
          <option value='blog'>Blog</option>
          <option value='influencer'>Influencer</option>
          <option value='photographer'>Photographer</option>
          <option value='designer'>Designer</option>
          <option value='brand'>Brand</option>
          <option value='model'>Model</option>
        </select>
    )
}

export default SelectCategory;